import styled from "styled-components"

export const HugeMobileFont = styled.span`
  font-size: ${(props) =>
    props.desktopFontSize ? props.desktopFontSize : "3rem"};
  line-height: ${(props) =>
    props.desktopLineHeight ? props.desktopLineHeight : "1.25"};
  @media screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 1.25;
  }
`

export const BigMobileFont = styled.span`
  font-size: ${(props) =>
    props.desktopFontSize ? props.desktopFontSize : "2rem"};
  line-height: ${(props) =>
    props.desktopLineHeight ? props.desktopLineHeight : "1.25"};
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.25;
  }
`

export const MediumMobileFont = styled.span`
  font-size: ${(props) =>
    props.desktopFontSize ? props.desktopFontSize : "2rem"};
  line-height: ${(props) =>
    props.desktopLineHeight ? props.desktopLineHeight : "1.25"};
  @media screen and (max-width: 768px) {
    font-size: 1.125rem;
    line-height: 1.22;
  }
`

export const SmallMobileFont = styled.span`
  font-size: ${(props) =>
    props.desktopFontSize ? props.desktopFontSize : "1.5rem"};
  line-height: ${(props) =>
    props.desktopLineHeight ? props.desktopLineHeight : "1.25"};
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.25;
  }
`
