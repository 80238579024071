import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/styledComponents"
import { BigMobileFont } from "../components/typography"

const PrivacyPolicy = ({ data }) => {
  const { content, yoast_meta, title } = data.wordpressPage
  return (
    <Layout>
      <SEO meta={yoast_meta} title={title} path="/privacy-policy" />
      <Container>
        <h1>
          <BigMobileFont desktopFontSize="3.5rem">{title}</BigMobileFont>
        </h1>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query PrivacyPolicyPageQuery {
    wordpressPage(template: { eq: "page-templates/page-privacy-policy.php" }) {
      title
      content
      yoast_meta {
        content {
          source_url
        }
        normalized_content
        name
        property
      }
    }
  }
`

export default PrivacyPolicy
